#cartItemIndex {
    display: flex;
    justify-content: center;
    width: 1000px
}

#indexCartItemName {
    font-family: Amazon Ember Regular;
    font-size: 18px;
}

#indexCartItemPrice {
    font-family: Amazon Ember Regular;
    font-size: 18px;
}

#cartNameDiv {
    display: flex;
    width: 60%;
    flex-wrap: wrap;
    margin-right: 20px;
    margin-top: 40px;
}

#cartPriceDiv {
    display: flex;
    margin-top: 40px;
}

#cartItemLine {
    background-color: #dddddd;
    height: 1px;
    margin-bottom: 20px;
}

#infoDiv {
    display: flex;
    width: 400px;
    position: relative;
    font-family: Amazon Ember Regular;
    left: 310px;
    top: -100px;
}

#deleteLink {
    font-family: Amazon Ember Regular;
    margin-right: 20px;
    font-size: 12px;
}

#quantityCartItem {
    margin-right: 20px;
    margin-left: 5px;
}

#deleteLink {
    cursor: pointer;
    color: #4093a1;
}

#deleteLink:hover {
    text-decoration: underline;
}