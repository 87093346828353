#modal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    /* top: 0;
    right: 0;
    left: 0;
    bottom: 0; */
}

#backgroundModal {
    /* position: fixed; */
    background-color: black;
    opacity: 0.25;
    top: 10vh;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 50;
}

#contentModal {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 100;
    top: 150%;
    width: 400px;
    height: 300px;
    background-color: white;
    border: 1px solid #bbbfbf;;
    /* background-color: white; */
}