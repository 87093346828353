#reviewCreationFormMain {
    display: flex;
    justify-content: center;
    height: 800px;
    width: 50vw;
    margin-top: 10vh;
    font-family: Amazon Ember Regular;
}

#reviewForm {
    display: flex;
    flex-direction: column;
    width: 30vw;
}

#reviewImage {
    height: 100px;
    margin-right: 20px;
}

#productName {
    line-height: normal;
}

.reviewLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    font-family: Amazon Ember Regular;
}

.reviewInput {
    margin-top: 10px;
    font-family: Amazon Ember Regular;
}

#reviewBody {
    display: flex;
    margin-top: 10px;
    height: 100px;
    font-family: Amazon Ember Regular;
}

#createReviewMain {
    margin-bottom: 20px;
    font-size: 24px;
}

#productInfo {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-family: Amazon Ember Regular;
}

#reviewLine {
    background-color: #dddddd;
    height: 1px;
    margin-bottom: 20px;
}

#loadingDiv {
    display: flex;
    justify-content: center;
    margin-top: 10vh;
    height: 800px;
    width: 100%;
}

#loadingIcon {
    width: 50px;
    height: 50px;
}