.userIcon {
    width: 30px;
}

#reviewShow {
    display: flex;
    flex-direction: column;
}

#reviewBodyDiv {
    font-size: 14px;
}

#userInfoDiv {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
}
.userIcon {
    margin-right: 10px;
}

#ratingTitle {
    display: flex;
    margin-bottom: 10px;
    font-size: 14px;
}

#reviewTitleH1 {
    margin-left: 10px;
}

#verifiedPurchaseDiv {
    font-size: 12px;
    color: #c45500;
    margin-bottom: 10px;
}

#reviewBodyDiv {
    margin-bottom: 20px;
}

#reviewNameH1 {
    font-size: 13px;
}

#reviewButtons {
    margin-bottom: 10px;
}

#deleteReviewButton {
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid #d5d9d9;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #ffffff;
    box-shadow: 1px 3px 3px #e9ecec;
    cursor: pointer;
}

#editReviewButton {
    border-radius: 7px;
    border: 1px solid #d5d9d9;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 22px;
    padding-right: 22px;
    background-color: #ffffff;
    box-shadow: 1px 3px 3px #e9ecec;
    cursor: pointer;
}

#editReviewButton:hover {
    background-color: #f4f2f2;
}

#deleteReviewButton:hover {
    background-color: #f4f2f2;
}