#cartItemsIndexDiv {
    display: flex;
    justify-content: center;
    background-color: #eaeded;
    width: 100%;
}

#cartItemsIndexDivInner {
    display: flex;
    background-color: white;
    padding: 30px;
    min-height: 1500px;
    margin-top: 30px;
}

#titleAndPrice {
    width: 1000px;
}

#cartTitle {
    display: flex;
    flex-direction: row;
    width: 300px;
    font-family: Amazon Ember Regular; 
    font-size: 22px
}

#shoppingCartTitle {
    width: 300px;
}


#priceTitle {
    display: flex;
    justify-content: right;
    font-family: Amazon Ember Regular;
    color: #565959;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

#subtotalPrice {
    display: flex;
    justify-content: right;
    font-family: Amazon Ember Regular;
}

#checkoutDiv {
    display: flex;
    padding-top: 20px;
    padding-left: 20px;
    justify-content: left;
    flex-direction: column;
    background-color: white;
    margin-top: 30px;
    margin-left: 20px;
    width: 270px;
    height: 200px;
}

#subtotalCheckout {
    font-family: Amazon Ember Regular;
    font-size: 18px;
    margin-bottom: 20px;
}

#checkoutButton {
    background-color: #ffd814;
    border-color: #a88734 #9c7e31 #846a29;
    font-family: Amazon Ember Regular;
    width: 250px;
    height: 29px;
    border: 1px rgb(132, 106, 41);
    cursor: pointer;
    border-radius: 10px;
}

#checkoutButton:hover {
    background-color: rgb(228, 184, 72)
}

#checkoutButton:active {
    background-color: rgb(215, 174, 69)
}

#checkoutSuccessDiv {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    background-color: white;
    min-height: 1500px;
    width: 80%;
    font-family: Amazon Ember Regular;
}

#innerCheckoutSuccessDiv {
    display: flex;
    justify-content: center;
    width: 60%;
    height: 100px;
    margin-top: 60px;
    margin-left: 40px;
    font-size: 24px;
    color: green;
}

.greenCheckmarkImage {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-bottom: 10px;
}