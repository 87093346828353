#navMainDiv {
    display: flex;
    justify-content: center;
    background-color: #0F1111;
    position: relative;
    width: 100%;
    height: 60px;
}

#cartCount {
    color: orange;
    font-family: Amazon Ember Regular;
    font-size: 20px;
    margin-left: 20px;
}

#innerCartDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#aboutLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-top: 5px;
    font-size: 28px;
    margin-right: 40px;
}

#linkedIn {
    
    color: white;
}

#githubLink {
    color: white;
    margin-right: 20px;
}
.rainierlogo {
    display: flex;
    width: 160px;
    height: 85px;
}

#navLogo {
    display: flex;
}

#signInDiv {
    display: flex;
    background-color: #0F1111;
    height: 40px;
    width: 100px;
    margin-left: 30px;
    margin-top: 10px;
    justify-content: center;
}

#signInDivText {
    display: flex;
    background-color: #0F1111;
    border: none;
    font-family: Amazon Ember Regular;
    color: white;
    height: 40px;
    width: 120px;
    font-size: 12px;
    margin-left: 40px;
    margin-top: 5px;
}

#categoriesSelector {
    text-align: center;
    height: 37px;
    margin-left: 30px;
    font-family: Amazon Ember Regular;
    background-color: #e7e9ec;;
    border: none;
    outline-color: orange;
    cursor: not-allowed;
}



#searchBar {
    min-width: 800px;
    overflow: auto;
    height: 20px;
    padding-top: 7px;
    padding-bottom: 10px;
    padding-left: 10px;
    font-family: Amazon Ember Regular;
    border: 1px ;
}

#searchBar:focus {
    outline: none;
}

#searchBarDiv {
    display: flex;
    width: 800px;
    margin-top: 10px;
}

#categoryDiv {
    display: flex;
    margin-top: 10px;
}



.magnifyingGlassIcon {
    height: 20px;
    width: 20px;
    background-color: none;
}

#magnifyingGlassDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 37px;
    width: 40px;
    margin-top: 10px;
    background-color: orange;
    outline-color: orange;
}

#shoppingCart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-top: 8px;
    margin-left: 30px;
}

.cartImage {
    width: 30px;
    margin-top: -5px;
    margin-left: 10px;

}

#cartText {
    color: white;
    font-family: Amazon Ember Regular;
    font-weight: lighter;
    height: 5px;
    font-size: 14px;
    margin-left: 3px;
}

#profileDiv {
    display: flex;
    height: 30px;
    margin-left: 10px;
}

#returnsOrders {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 50px;
    font-family: Amazon Ember Regular;
    font-size: 14px;
    cursor: not-allowed;
}

/* For Nav's Lower Components */

#navMainDivLower {
 
    display: flex;
    justify-content: center;
    background-color: #232f3e;
    width: 100%;
    height: 35px;
    
}

#toysCategory, #fashionCategory, #booksCategory, #electronicsCategory, #homegoodsCategory {
    color: white;
    font-family: Amazon Ember Regular;
    margin-right: 20px;
    margin-top: 8px;
    font-size: 14px;
}

