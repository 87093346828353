#loginModalDiv {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-family: Amazon Ember Regular;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 14px;
    z-index: 100;
}

#modalEntryDiv {
    color: white;
    display: flex;
    justify-content: center;
    margin-top: -8px;
}


#signInEntryButtonContainer {
    z-index: 100;
}


#signInEntryButton {
    display: flex;
    justify-content: center;
    background-color: rgb(240, 193, 75);
    border-color: #a88734 #9c7e31 #846a29;
    font-family: Amazon Ember Regular;
    width: 200px;
    height: 29px;
    margin-top: 20px;
    padding-top: 10px;
    border: 1px rgb(132, 106, 41);
    color: black;
}

#signInEntryButton:hover {
    background-color: rgb(227, 183, 72);
}

#signInEntryButton:active {
    background-color: rgb(214, 173, 69);
}

#newCustomer {
    display: flex;
    justify-content: center;
    font-family: Amazon Ember Regular;
    font-size: 13px;
    margin-top: 10px;
    color: blue;
}

#newCustomer:hover {
    color: orange;
    text-decoration: underline;
}