#profileModalDiv {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-family: Amazon Ember Regular;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 14px;
}

#modalEntryDiv {
    color: white;
    display: flex;
    justify-content: center;
}

/* #modalOpenedDiv {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-color: white;
    width: 300px;
    height: 300px;
    top: -260px;
    left: 500px;
} */

#signOutEntryButton {
    display: flex;
    justify-content: center;
    background-color: rgb(240, 193, 75);
    border-color: #a88734 #9c7e31 #846a29;
    font-family: Amazon Ember Regular;
    width: 200px;
    height: 29px;
    margin-top: 20px;
    padding-top: 10px;
    border: 1px rgb(132, 106, 41);
    color: black;
    cursor: pointer;
}

#signOutEntryButton:hover {
    background-color: rgb(227, 183, 72);
}

#signOutEntryButton:active {
    background-color: rgb(214, 173, 69);
}