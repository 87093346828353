#productIndex {
    display: flex;
    flex-direction: column;
    height: 500px;
    width: 300px;
    border: 1px solid #d5d9d9;
    margin: 10px;
    font-family: Amazon Ember Regular;
    border-radius: 6px;
}

#indexImageDiv {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    height: 200px;
    width: 300px;
}

#indexImage {
    max-height: 100%;
    max-width: 100%;
}

#indexProductName {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    width: 100%;
    line-height: 20px;
}

#indexProductReview {
    display: flex;
    margin-left: 5px;
    margin-bottom: 10px;
    color: #ffa41c;
}

#reviewsCountH1 {
    margin-left: 10px;
    color: #2b8899;
}

#indexProductPrice {
    font-size: 28px;
    margin-left: 5px;
    margin-bottom: 10px;
}

.primeLogoImage {
    width: 53px;
    height: 15px;
    margin-left: 5px;
}

#productShowAnchor {
    color: black;
    text-decoration: none;
}

#productShowAnchor:hover {
    color: #ffa41c;
}