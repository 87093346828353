#signInButton {
    background-color: rgb(240, 193, 75);
    border-color: #a88734 #9c7e31 #846a29;
    font-family: Amazon Ember Regular;
    width: 294px;
    height: 29px;
    margin-bottom: 20px;
    border: 1px rgb(132, 106, 41);
    cursor: pointer;
}

#signInButton:hover {
    background-color: rgb(228, 184, 72)
}

#signInButton:active {
    background-color: rgb(215, 174, 69)
}

.credential {
    font-family: Amazon Ember;
    font-size: 13px;
}

.password {
    font-family: Amazon Ember;
    font-size: 13px;
}

#credentialInput, #passwordInput {
    display: flex;
    margin-bottom: 20px;
    width: 280px;
    height: 23px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: 1px solid rgb(166, 166, 166);
    margin-top: 5px;
    outline-color: orange;
}

#loginError {
    font-family: Amazon Ember Regular;
    font-size: 13px;
    color: red;
}

#loginErrorsDiv {
    margin-bottom: 15px;
}

#loginMainDiv{
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
}

#loginSubDiv {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    align-items: center;
    width: 350px;
    height: 340px;
}

#signInHeader {
    display: flex;
    width: 300px;
}
#signInH3 {
    display: flex;
    width: 200px;
    font-family: Amazon Ember;
    font-size: 28px;
    padding-bottom: 20px;
    margin-top: 20px;
}

.rainierlogo2 {
    display: flex;
    width: 160px;
    height: 90px;
}

#logo {
    display: flex;
    justify-content: center;
}

#welcomeDiv {
    display: flex;
    justify-content: center;
    font-family: Amazon Ember Regular;
    color: #767676;
    font-size: 12px;
    margin-top: 15px;
}

#creationDiv {
    display: flex;
    justify-content: center;
}

#accountCreation {
    font-family: Amazon Ember Regular;
    font-weight: lighter;
    color: black;
    width: 327px;
    height: 29px;
    border: 1px solid gray;
    padding-left: 11px;
    padding-right: 10px;
    margin-top: 20px;
    cursor: pointer;
}

#accountCreation:hover {
    background-color: #d7d5d5;
}

#accountCreation:active {
    background-color: #c2c0c0;
}

#demoDiv {
    display: flex;
    justify-content: center;
}

#demoLoginButton {
    font-family: Amazon Ember Regular;
    margin-bottom: 20px;
    padding: 5px;
    width: 294px;
    height: 29px;
    border: 1px solid gray;
    cursor: pointer;
}

#demoLoginButton:hover {
    background-color: #d7d5d5;
}

#demoLoginButton:active {
    background-color: #c2c0c0;
}



