#fillerDiv {
    height: 50px;
    width: 100%;
    background-color: gray;
    opacity: 10%;
    margin-bottom: 80px;
}

#showMainDiv {
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-color: none;
    width: 100%;
    height: 100%;
}

#showImage {
    max-height: 100%;
    max-width: 100%;
}

#imageDiv {
    display: flex;
    justify-content: center;
    width: 600px;
    height: 600px;
    margin-right: 25px;
}

#descriptionDiv {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    width: 25%;
}

#productTitle {
    font-family: Amazon Ember Regular;
    line-height: 32px;
    font-size: 24px;
    margin-bottom: 20px;
}

#productRatings {
    font-family: Amazon Ember Regular;
    color: black;
    margin-bottom: 10px;
}

#lineSeparator {
    height: 1px;
    width: 100%;
    background-color: #d5d9d9;
    margin-bottom: 10px;
}

#productPrice1 {
    font-family: Amazon Ember Regular;
    font-size: 28px;
    margin-bottom: 20px;
}


#descriptionList {
    list-style-type: disc;
    margin-left: 15px;
}

#sentence {
    font-family: Amazon Ember Regular;
    font-size: 14px;
    margin-bottom: 10px;
}

#transactionDiv {
    display: flex;
    flex-direction: column;
    border: 1px solid #d5d9d9;
    font-family: Amazon Ember Regular;
    width: 206px;
    height: 400px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 10px;
}

#productPrice2 {
    font-size: 28px;
    margin-bottom: 20px;
    margin-top: 20px;
}

#freeReturns {
    font-family: Amazon Ember Regular;
    font-size: 14px;
    color: #007185;
    margin-bottom: 20px;
    cursor: not-allowed;
}

#freeDelivery {
    font-family: Amazon Ember Regular;
    font-size: 14px;
    color: #007185;
    margin-bottom: 20px;
    cursor: not-allowed;
}

#inStock {
    color: rgb(14 118 0);
    font-size: 18px;
    margin-bottom: 15px;
}

#quantityDiv {
    margin-bottom: 10px;
}

#quantity {
    font-size: 13px;
    margin-right: 5px;
}

#quantitySelector {
    background-color: #f0f2f2;
    width: 50px;
    border-radius: 5px;
    outline-color: #08c6de;
}

#addToCart {
    background-color: #ffd814;
    border-color: #a88734 #9c7e31 #846a29;
    font-family: Amazon Ember Regular;
    width: 200px;
    height: 35px;
    border: 1px rgb(132, 106, 41);
    cursor: pointer;
    border-radius: 200px;
    margin-bottom: 10px;
}

#addToCart:hover {
    background-color: #f5cf12;
}

#addToCart:active {
    background-color: #dfbd15;
}

#buyNow {
    background-color: #ffa41c;
    border-color: #a88734 #9c7e31 #846a29;
    font-family: Amazon Ember Regular;
    width: 200px;
    height: 35px;
    border: 1px rgb(132, 106, 41);
    cursor: pointer;
    border-radius: 200px;
    cursor: not-allowed;
}

#buyNow:hover {
    background-color: #f39c1a;
}

#buyNow:active {
    background-color: #e59319;
}

#paymentDiv {
    margin-top: 10px;
}

#shipsFromDiv, #soldByDiv, #returnsDiv {
    margin-top: 5px;
}

a {
    text-decoration: none; 
}

#payment, #shipsFrom, #soldBy, #returns {
    font-size: 12px;
    margin-right: 10px;
    color: #595c5c;
}


#secureTransaction, #eligibleForReturn {
    font-size: 12px;
    color: #358d9d;
    cursor: not-allowed;
}

#secureTransaction:hover, #eligibleForReturn:hover, #freeReturns:hover, #freeDelivery:hover {
    color: orange;
}

#rainierSiteLink {
    font-size: 12px;
}

#quantity1 {
    margin-right: 5px;
}

#ratingsDiv {
    display: flex;
}

#reviewsAmountH1 {
    margin-left: 20px;
    font-family: Amazon Ember Regular;
    color: #63a5b1;
}

#stars {
    margin-bottom: 10px;
    margin-left: 10px;
}

#starIcon {
    color: #ffa41c;
}

#starIconHalf {
    color: #ffa41c;
}

#starIconEmpty {
    color: #ffa41c;
}

#reviewsUpperDiv {
    margin-top: 80px;
    display: flex;
    font-family: Amazon Ember Regular;
    width: 50vw;
}

#customerReviewsDiv {
    width: 50vw;
    font-size: 24px;
    margin-left: 5vw;
}

#reviewEntry {
    display: flex;
    width: 50vw;
    align-items: flex-start;
    flex-direction: column;
}
#shareYourThoughts {
    margin-top: 10px;
    font-size: 14px;
}

#accountCreation {
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 1px 3px 3px #e9ecec;
    border: 1px solid #d5d9d9;
}

#customerRatingsDiv {
    display: flex;
    flex-direction: column;
}

#customerRatingsInnerDiv {
    display: flex;
    font-size: 18px;
    margin-top: 10px;
}

#customerStarsH1 {
    margin-right: 10px;
}

#customerReviewsAmountH1 {
    margin-top: 10px;
    font-size: 14px;
}

#separatorLine {
    width: 95vw;
    background-color: #bbbfbf;
    height: 1px;
    margin-top: 50px;
}

