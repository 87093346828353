#reviewsMainContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    font-family: Amazon Ember Regular;
    margin-top: 20px;
}

#review {
    margin-bottom: 20px;
    width: 50vw;
    padding-left: 10vw;
    background-color: white;
}
