#footerDiv {
    background-color: #232e3e;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 300px;
}

#fillerDiv2 {
    width: 100%;
    background-color: #37475a;
    height: 50px
}

#footerText {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#myName {
    color: white;
    font-family: Amazon Ember Regular;
    margin-bottom: 20px;
}

#credits {
    color: gray;
    font-family: Amazon Ember Regular;
    font-size: 12px;
}

#footerLinks {
    font-size: 35px;
    margin-bottom: 20px;
}