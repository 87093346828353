.signupMainDiv{
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
}

#signupSubDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    align-items: center;
    padding: 20px;
    margin: auto;
    width: 300px;
}

#createAccountHeader {
    font-family: Amazon Ember Regular;
    font-size: 28px;
    margin-bottom: 20px;
    width: 300px;
}

#signupError {
    display: flex;
    font-family: Amazon Ember Regular;
    font-size: 13px;
    color: red;
    margin-bottom: 8px;
}

#errors {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}


#signupLogo {
    display: flex;
    justify-content: center;
}


#signupNameLabel, #signupCredentialLabel, #signupPasswordLabel, #signupConfirmLabel {
    font-family: Amazon Ember Regular;
    font-weight: bolder;
    font-size: 13px;
    width: 280px;
    height: 23px;
}


#signupNameInput, #signupCredentialInput, #signupPasswordInput, #signupConfirmInput {
    display: flex;
    margin-bottom: 20px;
    width: 280px;
    height: 23px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: 1px solid rgb(166, 166, 166);
    margin-top: 5px;
    font-family: Amazon Ember Regular;
    outline-color: orange;
}





.signupButton2 {
    background-color: rgb(240, 193, 75);
    border-color: #a88734 #9c7e31 #846a29;
    font-family: Amazon Ember Regular;
    width: 294px;
    height: 29px;
    margin-bottom: 20px;
    border: 1px rgb(132, 106, 41);
    cursor: pointer;
}

.signupButton2:hover {
    background-color: rgb(227, 183, 72);
}

.signupButton2:active {
    background-color: rgb(216, 174, 69);
}